import React from 'react'
import _ from 'lodash'
import ReactDOM from 'react-dom/client'
import Slideshow from './Slideshow'
import { ApolloProvider, gql, useQuery } from '@apollo/client'
import { client } from '../../ApolloClient'
import useWindowSize, { IWindowSize } from '../../../hooks/useWindowSize'

const FETCH_DATA = gql`
    query NewQuery {
        clients(
            first: 1000
            where: { orderby: { field: MENU_ORDER, order: DESC } }
        ) {
            nodes {
                title
                logo
            }
        }
    }
`

const OurClients = () => {
    const winSize: IWindowSize | undefined = useWindowSize()

    const imageWidth = () => {
        // console.log('winSize =', winSize)
        if (winSize !== undefined) {
            if (winSize?.width < 576) {
                return '150px'
            }
            if (winSize?.width < 768) {
                return '200px'
            }
            if (winSize?.width < 992) {
                return '240px'
            }
            if (winSize?.width < 1200) {
                return '278px'
            }
        }
        return '278px'
    }

    const { data, loading, error } = useQuery(FETCH_DATA)

    if (loading) {
        return null
    }

    if (error) {
        return null
    }

    const num = Math.ceil(data.clients.nodes.length / 3)
    const DATA = _.chunk(data.clients.nodes, num)

    return (
        <div className="container-fluid g-0">
            <div className="row my-2 justify-content-center">
                <Slideshow
                    data={DATA[0]}
                    reverseDirection={false}
                    imageWidth={imageWidth()}
                />
            </div>
            <div className="row my-2 justify-content-center">
                <Slideshow
                    data={DATA[2]}
                    reverseDirection={true}
                    imageWidth={imageWidth()}
                />
            </div>
            <div className="row my-2 justify-content-center">
                <Slideshow
                    data={DATA[1]}
                    reverseDirection={false}
                    imageWidth={imageWidth()}
                />
            </div>
        </div>
    )
}

const element = document.getElementById('home__clients') as HTMLElement

if (typeof element != 'undefined' && element != null) {
    const home__clients = ReactDOM.createRoot(element)
    home__clients.render(
        <React.StrictMode>
            <ApolloProvider client={client}>
                <OurClients />
            </ApolloProvider>
        </React.StrictMode>
    )
}
