import React from 'react'
import Masonry from 'masonry-layout'
import ReactDOM from 'react-dom/client'
import S from './TeamMantis.module.sass'
import classNames from 'classnames'
import FlipCard from './FlipCard'
import { ApolloProvider, gql, useQuery } from '@apollo/client'
import { client } from '../../ApolloClient'
import { IEmpolyee } from './DATA'

const EMPLOYEES_DATA = gql`
    query FetchEmployeeData {
        employees(
            first: 100
            where: { orderby: { field: MENU_ORDER, order: ASC } }
        ) {
            nodes {
                title
                firstName
                lastName
                jobPosition
                emailAddress
                portraitImage
                id
                isVisible
            }
        }
    }
`

const TeamMantis = () => {
    const { data, loading, error } = useQuery(EMPLOYEES_DATA)

    const [layout, setLayout] = React.useState()

    const [z, changeZ] = React.useState(0)

    React.useEffect(() => {
        const m = new Masonry(`.mantis-team`, {
            itemSelector: `.staff`,
            // gutter: 10,
            gutter: 0,
            percentPosition: true,
            fitWidth: true,
            stamp: `.empty`,
            originLeft: false,
        })

        // @ts-ignore
        setLayout(m)
    }, [data])

    window.addEventListener('resize', () => {
        if (layout !== undefined) {
            // @ts-ignore
            layout?.layout()
        }
    })

    return (
        <div className={classNames(S['mantis-team'], 'mantis-team')}>
            {!loading && !error ? (
                <>
                    <div className={classNames(['empty', S.empty, S.empty1])}>
                        <div className={S.space} />
                    </div>
                    <div className={classNames(['empty', S.empty, S.empty2])}>
                        <div className={S.space} />
                    </div>
                    <div className={classNames(['empty', S.empty, S.empty3])}>
                        <div className={S.space} />
                    </div>
                    <div className={classNames(['empty', S.empty, S.empty4])}>
                        <div className={S.space} />
                    </div>
                    {data.employees.nodes.map((employee: IEmpolyee | any) => {
                        return employee.isVisible === 'true' ? (
                            <FlipCard
                                key={employee.id}
                                employee={employee}
                                z={z}
                                changeZ={changeZ}
                            />
                        ) : null
                    })}
                </>
            ) : null}
        </div>
    )
}

const element = document.getElementById(
    'team-mantis__staffs-container'
) as HTMLElement

if (typeof element != 'undefined' && element != null) {
    const team_mantis_container = ReactDOM.createRoot(element)
    team_mantis_container.render(
        <React.StrictMode>
            <ApolloProvider client={client}>
                <TeamMantis />
            </ApolloProvider>
        </React.StrictMode>
    )
}
