import React from "react";
import parse from "html-react-parser";
import { Swiper, SwiperSlide } from "swiper/react";
import { EffectFade } from "swiper";
import "swiper/css";
import "swiper/css/effect-fade";
import { gql, useQuery } from "@apollo/client";

import firstImage from "../../../images/home/adaptable-all.png";

type Props = {
  index: number;
};

const FETCH_DATA = gql`
  query NewQuery {
    serviceTypes(where: { orderby: TERM_ID }) {
      nodes {
        name
        description
      }
    }
  }
`;

const Slideshow = ({ index }: Props) => {
  const { data, loading, error } = useQuery(FETCH_DATA);
  // @ts-ignore
  const swiper = React.useRef(null);
  React.useEffect(() => {
    if (!loading && !error) {
      // const i = index === -1 ? 0 : index;
      // @ts-ignore
      swiper.current.swiper?.slideTo(index, 1000);
    }
  }, [index, swiper, data, loading, error]);

  if (loading) {
    return null;
  }

  if (error) {
    return null;
  }

  const serviceTypes = data.serviceTypes.nodes;

  return (
    <Swiper
      allowTouchMove={false}
      ref={swiper}
      modules={[EffectFade]}
      effect="fade"
      initialSlide={1}
      spaceBetween={0}
      slidesPerView={1}
      // onSlideChange={() => console.log("slide change")}
    >
      <SwiperSlide key={0}>
        <img src={firstImage} />
      </SwiperSlide>
      {
        // @ts-ignore
        serviceTypes.map((item: any, index) => (
          <SwiperSlide key={index + 1}>{parse(item.description)}</SwiperSlide>
        ))
      }
    </Swiper>
  );
};

export default Slideshow;
