import React from 'react'
import { Swiper, SwiperSlide } from 'swiper/react'
import { Autoplay } from 'swiper'
import 'swiper/css'
import 'swiper/css/autoplay'

type Props = {
    data: Array<any>
    reverseDirection: boolean
    imageWidth: string
}

const Slideshow = ({ data, reverseDirection, imageWidth }: Props) => {
    const newData = [...data, ...data, ...data]

    return (
        <Swiper
            autoHeight={true}
            modules={[Autoplay]}
            centeredSlides={reverseDirection}
            loop={true}
            autoplay={{
                delay: 0,
                reverseDirection: reverseDirection,
            }}
            spaceBetween={10}
            slidesPerView={`auto`}
            speed={5000}
        >
            {newData.map((client: any, index) => (
                <SwiperSlide style={{ width: imageWidth }} key={index}>
                    <img
                        src={client.logo}
                        alt={`The logo of ${client.title}`}
                    />
                </SwiperSlide>
            ))}
        </Swiper>
    )
}

export default Slideshow
