import React from 'react'
import ReactDOM from 'react-dom/client'
import S from './AdaptableAnySituation.module.sass'
import classNames from 'classnames'
import Slideshow from './Slideshow'
import { ApolloProvider, gql, useQuery } from '@apollo/client'
import { client } from '../../ApolloClient'

const FETCH_DATA = gql`
    query NewQuery {
        pages(where: { title: "Home" }) {
            edges {
                node {
                    title
                    pageHome {
                        homeSection2Heading {
                            homeSection2HeadingLine1
                            homeSection2HeadingLine2
                            homeSection2HeadingLine3
                        }
                        homeSection2Button {
                            homeSection2ButtonLabel
                            homeSection2ButtonLink {
                                ... on Page {
                                    link
                                }
                            }
                        }
                    }
                }
            }
        }
        serviceTypes(where: { orderby: TERM_ID }) {
            nodes {
                name
                services(
                    where: { orderby: { field: MENU_ORDER, order: DESC } }
                ) {
                    nodes {
                        title
                    }
                }
            }
        }
    }
`
const AdaptableAnySituation = () => {
    const [itemIndex, setItemIndex] = React.useState(0)

    const { data, loading, error } = useQuery(FETCH_DATA)

    const accordionItemStyle = (index: number) => {
        const classes = [
            'accordion-item',
            S['accordion-item'],
            {
                [`${S.active}`]: itemIndex === index,
            },
        ]
        return classNames(classes)
    }

    if (loading) {
        return null
    }

    if (error) {
        return null
    }

    // console.log('data =', data)

    const serviceTypes = data.serviceTypes.nodes

    // console.log('serviceTypes = ', serviceTypes)

    return (
        <div className={S['adaptable-any-situation']}>
            <div className="row flex-column flex-lg-row align-items-center align-items-lg-start">
                <div className="col-7 col-lg-4 mt-3 mt-lg-0">
                    <div className={S['slideshow-adaptable']}>
                        <Slideshow index={itemIndex} />
                    </div>
                </div>
                <div className="col-10 col-lg-8 gx-0 gx-lg-3">
                    <div
                        className={`accordion-adaptable ${S['accordion-adaptable']}`}
                    >
                        {
                            // @ts-ignore
                            serviceTypes.map((item: any, index) => (
                                <div
                                    className={accordionItemStyle(index + 1)}
                                    key={index + 1}
                                >
                                    <h1
                                        className={S.heading}
                                        onClick={() =>
                                            setItemIndex(
                                                index + 1 === itemIndex
                                                    ? -1
                                                    : index + 1
                                            )
                                        }
                                    >
                                        {item.name}
                                    </h1>
                                    <div className={S.list}>
                                        {item.services.nodes.map(
                                            // @ts-ignore
                                            (list: any, index) => (
                                                <div
                                                    key={index}
                                                    className={S.item}
                                                >
                                                    {list.title}
                                                </div>
                                            )
                                        )}
                                    </div>
                                </div>
                            ))
                        }
                        <div
                            className={`see-our-work ${S['see-our-work']}`}
                            onClick={() => {
                                window.open(
                                    `${data.pages.edges[0].node.pageHome.homeSection2Button.homeSection2ButtonLink.link}`,
                                    '_self'
                                )
                            }}
                        >
                            {
                                data.pages.edges[0].node.pageHome
                                    .homeSection2Button.homeSection2ButtonLabel
                            }
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

const element = document.getElementById(
    'home__any-situation__application'
) as HTMLElement

if (typeof element != 'undefined' && element != null) {
    const home__any_situation = ReactDOM.createRoot(element)
    home__any_situation.render(
        <React.StrictMode>
            <ApolloProvider client={client}>
                <AdaptableAnySituation />
            </ApolloProvider>
        </React.StrictMode>
    )
}
