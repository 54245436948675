import React from 'react'
import ReactDOM from 'react-dom/client'
import image from '../../../images/who-we-are/office.png'
import S from './StudioTour.module.sass'
import { ApolloProvider, gql, useQuery } from '@apollo/client'
import { client } from '../../ApolloClient'

const FETCH_DATA = gql`
    query NewQuery {
        pages(where: { title: "Who We Are" }) {
            edges {
                node {
                    title
                    pageWhoWeAre {
                        whoWeAreSection4Description
                    }
                }
            }
        }
    }
`

const StudioTour = () => {
    const { data, loading, error } = useQuery(FETCH_DATA)

    if (loading) {
        return null
    }

    if (error) {
        return null
    }

    return (
        <div className={S['studio-tour']}>
            <div className="container-fluid">
                <div className="row gx-0 justify-content-center justify-content-xl-start">
                    <div className="col-11 offset-xl-2 col-xl-3 order-1 order-xl-0">
                        <div className="h-100 d-flex flex-column justify-content-end">
                            <div className={S['studio-desc']}>
                                {
                                    data.pages.edges[0].node.pageWhoWeAre
                                        .whoWeAreSection4Description
                                }
                            </div>
                        </div>
                    </div>

                    <div className="col-12 col-xl-7 order-0 order-xl-1">
                        <div className="d-flex justify-content-center">
                            <div className={S['doll-house']}>
                                <img
                                    src={image}
                                    alt="image of mantis creative studio"
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

const element = document.getElementById(
    'mantis-interactive-studio-tour'
) as HTMLElement

if (typeof element != 'undefined' && element != null) {
    const interactive_studio_tour = ReactDOM.createRoot(element)
    interactive_studio_tour.render(
        <React.StrictMode>
            <ApolloProvider client={client}>
                <StudioTour />
            </ApolloProvider>
        </React.StrictMode>
    )
}
