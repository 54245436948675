import React from 'react'
import parse from 'html-react-parser'
import ReactDOM from 'react-dom/client'
import S from './FactsAccordion.module.sass'
import classNames from 'classnames'
import { client } from '../../ApolloClient'
import { ApolloProvider, gql, useQuery } from '@apollo/client'
import _ from 'lodash'

const FETCH_DATA = gql`
    query NewQuery {
        allFAQ(where: { orderby: { field: MENU_ORDER, order: ASC } }) {
            nodes {
                content
                title
            }
        }
    }
`

const FactsAccordion = () => {
    const { data, loading, error } = useQuery(FETCH_DATA)

    const [tab, changetab] = React.useState(-1)

    const click = (t: number) => {
        changetab(t)
    }

    const tabStyle = (t: number) => {
        const classes = [
            'straight-facts__accordion-tab',
            S.tab,
            {
                [`${S.active}`]: t === tab,
            },
        ]
        return classNames(classes)
    }

    if (loading) {
        return null
    }

    if (error) {
        return null
    }

    // console.log('data =', data)

    const num = Math.ceil(data.allFAQ.nodes.length / 2)

    const DATA = _.chunk(data.allFAQ.nodes, num)

    return (
        <div
            className={classNames(S['facts-accordion-container'], 'container')}
        >
            <div className="row gx-5 justify-content-center">
                <div className="col-10 col-lg-6">
                    {
                        // @ts-ignore
                        DATA[0].map((question: any, index) => (
                            <div className={tabStyle(index)}>
                                <div
                                    className={S.header}
                                    onClick={() => click(index)}
                                >
                                    {question.title}
                                </div>
                                <div className={S.body}>
                                    {parse(question.content)}
                                </div>
                            </div>
                        ))
                    }
                </div>

                <div className="col-10 col-lg-6">
                    {
                        // @ts-ignore
                        DATA[1].map((question: any, index) => (
                            <div className={tabStyle(index + 3)}>
                                <div
                                    className={S.header}
                                    onClick={() => click(index + 3)}
                                >
                                    {question.title}
                                </div>
                                <div className={S.body}>
                                    {parse(question.content)}
                                </div>
                            </div>
                        ))
                    }
                </div>
            </div>
        </div>
    )
}

const element = document.getElementById(
    'straight-facts-accordion'
) as HTMLElement

if (typeof element != 'undefined' && element != null) {
    const straight_facts_accordion = ReactDOM.createRoot(element)
    straight_facts_accordion.render(
        <React.StrictMode>
            <ApolloProvider client={client}>
                <FactsAccordion />
            </ApolloProvider>
        </React.StrictMode>
    )
}
