import React, { SyntheticEvent } from 'react'
import classNames from 'classnames'
import S from './TeamMantis.module.sass'
import { IEmpolyee } from './DATA'
import IconEmail from '../../../images/who-we-are/icon-email.svg'

type Props = {
    employee: IEmpolyee | any
    z: number
    changeZ: (z: number) => void
}
const FlipCard = ({ employee, z, changeZ }: Props) => {
    const card = React.useRef(null)

    const [flip, changeFlip] = React.useState(false)
    const staffCardStyle = () => {
        const classes = [
            S['staff-card'],
            {
                [`${S.normal}`]: !flip,
                [`${S.flip}`]: flip,
            },
        ]
        return classNames(classes)
    }

    const click = (event: SyntheticEvent) => {
        // if (card !== null && card.current !== undefined) {
        //     const newZIndex = z + 1
        //     // @ts-ignore
        //     card.current.style.zIndex = String(newZIndex)
        //     changeZ(newZIndex)
        // }
        //
        // changeFlip(true)
        //
        // setTimeout(() => {
        //     changeFlip(false)
        // }, 5000)
    }

    // @ts-ignore
    return (
        <div
            ref={card}
            className={classNames(S.staff, 'staff')}
            onClick={click}
        >
            <div className={staffCardStyle()}>
                <div className={S['flip-card-inner']}>
                    <div
                        className={S['flip-card-front']}
                        style={{
                            backgroundImage: `url(${employee.portraitImage})`,
                        }}
                    >
                        <div
                            className={S.name}
                            style={
                                // @ts-ignore
                                { '--letters': employee.firstName.length }
                            }
                        >
                            <svg
                                // height="100%"
                                preserveAspectRatio="xMidYMid meet"
                            >
                                <text
                                    x="50%"
                                    y="98%"
                                    textAnchor="middle"
                                    // textLength="100%"
                                    lengthAdjust="spacing"
                                >
                                    {employee.firstName}
                                </text>
                            </svg>
                        </div>
                        <div className={S.job}>{employee.jobPosition}</div>
                    </div>
                    <div className={S['flip-card-back']}>
                        <div className={S.top}>
                            <h3>
                                {employee.firstName}
                                <br />
                                {employee.lastName}
                            </h3>
                            <p>{employee.jobPosition}</p>
                        </div>
                        <div className={S.social}>
                            {employee?.emailAddress && (
                                <a
                                    title={`Send an email to ${employee.title}`}
                                    href={`mailto:${employee?.emailAddress}`}
                                >
                                    <img src={IconEmail} alt="icon of email" />
                                </a>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default FlipCard
