import { useEffect, useState } from 'react'

export interface IWindowSize {
    width: number
    height: number
}

const useWindowSize = () => {
    const [windowSize, setWindowSize] = useState<IWindowSize>()
    useEffect(() => {
        const handleResize = () => {
            setWindowSize({
                width: window.innerWidth,
                height: window.innerHeight,
            })
        }
        window.addEventListener('load', handleResize)
        window.addEventListener('resize', handleResize)
        handleResize()
        return () => window.removeEventListener('resize', handleResize)
    }, [])
    return windowSize
}

export default useWindowSize
